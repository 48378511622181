.section7-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px 200px;
  width: 100vw;
  height: max-content;
  background: var(--white-color);

  .section7-container-details {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;

    .section7-heading {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      text-align: center;
      width: 100%;

      .span-shape {
        display: flex;
        align-items: center;
        gap: 10px;
        margin-bottom: 10px;

        .shape {
          position: relative;
          width: 50px;
          height: 2px;
          background-color: var(--purple-color);

          &::before,
          &::after {
            content: "";
            position: absolute;
            background-color: var(--purple-color);
            height: 2px;
          }

          &::before {
            width: 30px;
            top: -5px;
            left: 0;
          }

          &::after {
            width: 30px;
            bottom: -5px;
            left: 0;
          }
        }

        span {
          font-size: 16px;
          color: var(--purple-color);
          font-weight: var(--medium);
          text-transform: uppercase;
        }
      }

      h3 {
        font-size: var(--h1-font);
        font-weight: var(--regular);
        text-transform: capitalize;
        color: transparent;
        background-image: linear-gradient(
          to right,
          var(--magenta-bright-color),
          var(--violet-dark-color)
        );
        background-clip: text;
        -webkit-background-clip: text;
      }

      p {
        font-size: var(--normal-font);
        text-align: center;
        line-height: 1.6;
      }
    }

    .section7-details {
      margin-top: 20px;
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-gap: 20px;
      width: 100%;

      .details-container {
        background-color: var(--white-color);
        border: 1px solid #ddd;
        border-radius: 8px;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
        display: flex;
        flex-direction: column;
        padding: 20px;
        width: 100%;
        transition: transform 0.3s ease, box-shadow 0.3s ease;

        &:hover {
          transform: translateY(-5px);
          box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.1);
        }

        .details-header {
          display: flex;
          justify-content: space-between;
          align-items: center;
          cursor: pointer;

          h4 {
            font-size: var(--h3-font);
            font-weight: var(--regular);
            text-transform: capitalize;
            color: transparent;
            background-image: linear-gradient(
              to right,
              var(--magenta-bright-color),
              var(--violet-dark-color)
            );
            background-clip: text;
            -webkit-background-clip: text;
          }

          svg {
            font-size: 1.2rem;
            color: var(--purple-color);
          }
        }

        .details-content {
          margin-top: 20px;
          display: flex;
          justify-content: space-between;;
          align-items: center;
          gap: 10px;

          img {
            max-width: 150px;
            max-height: 150px;
            object-fit: contain;
          }

          p {
            font-size: var(--normal-font);
            text-align: justify;
            color: var(--gray-color);
          }
        }
      }
    }
  }
}
