.section5-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px 200px;
  width: 100vw;
  height: max-content;
  background: var(--white-color);

  .section5-container-details {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    flex-wrap: nowrap;

    .section5-heading {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      text-align: center;
      width: 100%;

      .span-shape {
        display: flex;
        align-items: center;
        gap: 10px;
        margin-bottom: 10px;

        .shape {
          position: relative;
          width: 50px;
          height: 2px;
          background-color: var(--purple-color);

          &::before,
          &::after {
            content: "";
            position: absolute;
            background-color: var(--purple-color);
            height: 2px;
          }

          &::before {
            width: 30px;
            top: -5px;
            left: 0;
          }

          &::after {
            width: 30px;
            bottom: -5px;
            left: 0;
          }
        }

        span {
          font-size: 16px;
          color: var(--purple-color);
          font-weight: var(--medium);
          text-transform: uppercase;
        }
      }

      h3 {
        font-size: var(--h1-font);
        font-weight: var(--regular);
        text-transform: capitalize;
        color: transparent;
        background-image: linear-gradient(
          to right,
          var(--magenta-bright-color),
          var(--violet-dark-color)
        );
        background-clip: text;
        -webkit-background-clip: text;
      }

      p {
        font-size: var(--normal-font);
        text-align: center;
        line-height: 1.6;
      }
    }

    .section5-tabs {
      display: flex;
      width: 100%;
      margin-top: 20px;

      .vertical-tabs {
        flex: 1;
        display: flex;
        flex-direction: column;
        gap: 10px;

        .tab-item {
          display: flex;
          align-items: center;
          padding: 15px;
          cursor: pointer;
          border-bottom: 1px solid var(--magenta-bright-color);
          border-right: 1px solid var(--magenta-bright-color);
          border-bottom-right-radius: 10px;
          transition: all 0.3s ease, color 0.3s ease;

          &.active {
            transform: scale(1.1);
          }

          &:hover {
            transform: scale(1.1);
          }

          .tab-icon {
            width: 40px;
            height: 40px;
            margin-right: 15px;
          }

          .tab-heading {
            font-size: var(--small-font);
            font-weight: var(--medium);
            text-transform: capitalize;
          }
        }
      }

      .tab-content {
        flex: 3;
        padding: 20px;
        display: flex;
        flex-direction: column;
        padding-left: 30px;

        .tab-content-body {
          display: flex;
          flex-direction: column;
          gap: 10px;
          justify-content: flex-start;

          .tab-body-content-heading {
            display: flex;
            align-items: center;
            gap: 10px;

            .content-icon {
              width: 40px;
              height: 40px;
            }

            .content-heading {
              font-size: var(--h2-font);
              font-weight: var(--medium);
              margin-bottom: 10px;
              text-transform: capitalize;
              margin: 0;
              color: transparent;
              background-image: linear-gradient(
                to right,
                var(--magenta-bright-color),
                var(--violet-dark-color)
              );
              background-clip: text;
              -webkit-background-clip: text;
            }
          }

          .content-description {
            font-size: var(--normal-font);
            line-height: 1.6;
            color: var(--gray-color);
          }
        }
      }
    }
  }
}
