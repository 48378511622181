.section1-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 120px;
  padding: 0 200px;
  width: 100vw;
  min-height: 500px;
  background: var(--white-color);

  .section1-details {
    display: flex;
    justify-content: space-between;
    gap: 20px;
    width: 100%;
    align-items: center;
    flex-wrap: nowrap;
    transition: opacity 0.5s ease;
    opacity: 0;
    animation: fadeIn 0.5s forwards, slideInLeft 1s ease-out forwards;

    .section1-intro {
      flex: 3;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 10px;
      opacity: 0;
      animation: slideInFromLeft 1s ease-out forwards;

      .span-shape {
        display: flex;
        align-items: center;
        gap: 10px;
        margin-bottom: 10px;

        .shape {
          position: relative;
          width: 50px;
          height: 2px;
          background-color: var(--purple-color);

          &::before,
          &::after {
            content: "";
            position: absolute;
            background-color: var(--purple-color);
            height: 2px;
          }

          &::before {
            width: 30px;
            top: -5px;
            left: 0;
          }

          &::after {
            width: 30px;
            bottom: -5px;
            left: 0;
          }
        }

        span {
          font-size: 16px;
          color: var(--purple-color);
          font-weight: var(--medium);
          text-transform: uppercase;
        }
      }

      h3 {
        font-size: var(--h1-font);
        font-weight: var(--regular);
        margin-top: 10px;
        text-transform: capitalize;
        color: transparent;
        background-image: linear-gradient(
          to right,
          var(--magenta-bright-color),
          var(--violet-dark-color)
        );
        background-clip: text;
        -webkit-background-clip: text;
      }

      p {
        font-size: var(--normal-font);
        text-align: justify;
        line-height: 1.6;
      }

      .primary-button {
        margin: 0;
        margin-top: 20px;
      }
    }

    .section1-image {
      flex: 2;
      width: 100%;
      opacity: 0;
      animation: slideInFromRight 1s ease-out forwards;

      img {
        width: 100%;
        height: auto;
        min-height: 300px;
        max-height: 300px;
        object-fit: contain;
      }
    }
  }

  .dots-container {
    display: flex;
    justify-content: center;
    gap: 10px;
    margin: 20px;

    .dot {
      width: 12px;
      height: 12px;
      background-color: #ccc;
      border-radius: 50%;
      cursor: pointer;
      transition: background-color 0.3s ease;
    }

    .dot.active {
      background-color: var(--purple-color);
    }
  }
}
