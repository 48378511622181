.footer {
  background: linear-gradient(
    to right,
    var(--magenta-bright-color),
    var(--violet-dark-color)
  );
  width: 100vw;
  color: var(--white-color);
  padding: 60px 20px;
  position: relative;
  overflow: hidden;

  .footer-container {
    display: flex;
    justify-content: space-between;
    width: 100vw;
    margin: 0;
    flex-wrap: wrap;
    gap: 60px;
  }

  .footer-column {
    flex: 1;
    min-width: 250px;
    margin: 20px 0;
    padding: 20px;

    &:first-child {
      flex: 0 0 48%;
    }

    &:nth-child(2) {
      flex: 0 0 15%;
    }

    &:nth-child(2) {
      flex: 0 0 20%;
    }

    .animated-logo {
      display: flex;
      gap: 10px;
      margin-bottom: 20px;

      .animated-letter {
        font-size: var(--extra-large-font);
        font-weight: var(--bold);
        opacity: 0;
        transform: translateY(20px);
        animation: fadeInUp 2s ease-in-out infinite;
        animation-fill-mode: both;
      }

      .animated-letter:nth-child(1) {
        animation-delay: 0s;
      }
      .animated-letter:nth-child(2) {
        animation-delay: 0.2s;
      }
      .animated-letter:nth-child(3) {
        animation-delay: 0.4s;
      }
      .animated-letter:nth-child(4) {
        animation-delay: 0.6s;
      }
      .animated-letter:nth-child(5) {
        animation-delay: 0.8s;
      }
      .animated-letter:nth-child(6) {
        animation-delay: 1s;
      }
      .animated-letter:nth-child(7) {
        animation-delay: 1.2s;
      }
      .animated-letter:nth-child(8) {
        animation-delay: 1.4s;
      }
    }

    h3 {
      font-size: var(--h3-font);
      margin-bottom: 20px;
      color: var(--white-color);
    }

    p,
    ul {
      font-size: var(--p-font);
      line-height: 1.8;
    }

    ul {
      list-style: none;
      padding: 0;

      li {
        margin: 10px 0;

        a {
          color: var(--light-gray-color);
          text-decoration: none;

          &:hover {
            color: var(--white-color);
          }
        }
      }
    }

    .social-icons {
      display: flex;
      gap: 15px;
      margin-top: 20px;

      a {
        color: var(--light-gray-color);
        font-size: var(--h3-font);

        &:hover {
          color: var(--purple-color);
        }
      }
    }
  }

  @keyframes fadeInUp {
    0% {
      opacity: 0;
      transform: translateY(20px);
    }
    50% {
      opacity: 1;
      transform: translateY(0);
    }
    100% {
      opacity: 0;
    }
  }
}

@media (max-width: 1024px) {
  .footer-column {
    &:first-child {
      flex: 0 0 35% !important;
    }
    .animated-logo {
      .animated-letter {
        font-size: 60px !important;
      }
    }
  }
}

@media (max-width: 768px), (max-width: 576px) {
  .footer-column {
    &:first-child {
      flex: 0 0 100% !important;
    }
    .animated-logo {
      .animated-letter {
        font-size: 75px !important;
      }
    }
  }
}

@media (max-width: 480px) {
  .footer-column {
    margin: 0 !important;
    padding: 10px !important;

    &:first-child {
      flex: 0 0 90% !important;
    }
    .animated-logo {
      .animated-letter {
        font-size: 50px !important;
      }
    }
  }
}

@media (max-width: 320px) {
  .footer-column {
    margin: 0 !important;
    padding: 10px !important;

    &:first-child {
      flex: 0 0 90% !important;
    }
    .animated-logo {
      .animated-letter {
        font-size: 32px !important;
      }
    }
  }
}
