@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Tajawal:wght@200;300;400;500;700;800;900&display=swap");

:root {
  /* font */
  --default-font: "Poppins", sans-serif;
  /* color codes */
  --purple-color: #642c8f;
  --purple-deep-color: #60308f;
  --purple-dark-color: #5c2b89;
  --purple-shade-color: #252060;
  --magenta-color: #9f258f;
  --magenta-bright-color: #a3238c;
  --violet-color: #502980;
  --violet-deep-color: #5f2f8e;
  --violet-dark-color: #572b86;
  --white-color: #fff;
  --light-gray-color: #e6e6e6;
  --extra-light-gray-color: #fafafa;
  --gray-color: #6c757d;
  --dark-gray-color: #343a40;
  --primary-color: #007bff;
  --secondary-color: #6c757d;
  --success-color: #28a745;
  --info-color: #17a2b8;
  --warning-color: #ffc107;
  --danger-color: #dc3545;
  --light-color: #f5fcfe;
  --dark-color: #343a40;
  --slate-color: #738bae;
  --black-color: #000;
  --light-black-color: #041b41;

  /* font-size */
  --extra-large-font: 120px;
  --big-font: 60px;
  --normal-font: 16px;
  --h1-font: 40px;
  --h2-font: 24px;
  --h3-font: 20px;
  --h4-font: 18px;
  --h5-font: 16px;
  --h6-font: 14px;
  --p-font: 16px;

  /* font-weight */
  --thin: 100;
  --extra-light: 200;
  --light: 300;
  --regular: 400;
  --medium: 500;
  --semibold: 600;
  --bold: 700;
  --extrabold: 800;
  --black: 900;

  /* box-shadow */
  --box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  line-height: 1.6;
  letter-spacing: 1px;
}

body {
  margin: 0;
  padding: 0;
  min-height: 100vh;
  font-family: "Poppins", sans-serif;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: var(--white-color);
  color: var(--dark-color);
  overflow-x: hidden;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
}

h1 {
  font-size: var(--h1-font);
}

ol,
ul {
  list-style: none;
}

a {
  text-decoration: none;
  color: var(--primary-color);
  cursor: pointer;
}

a:hover {
  color: var(--secondary-color);
  text-decoration: none;
}

.primary-button {
  background-image: linear-gradient(
    to right,
    var(--magenta-bright-color) 0%,
    var(--violet-dark-color) 51%,
    var(--magenta-bright-color) 100%
  );
}
.primary-button {
  margin: 10px;
  padding: 15px 45px;
  text-align: center;
  text-transform: uppercase;
  transition: 0.5s;
  border: none;
  background-size: 200% auto;
  color: white;
  box-shadow: 0 0 20px #eee;
  border-radius: 10px;
  display: block;
}

.primary-button:hover {
  background-position: right center;
  color: #fff;
  text-decoration: none;
  cursor: pointer;
}

.btn-quote {
  background-image: linear-gradient(
    to right,
    #7b4397 0%,
    #dc2430 51%,
    #7b4397 100%
  );
}
.btn-quote {
  margin: 10px;
  padding: 15px 45px;
  text-align: center;
  text-transform: uppercase;
  transition: 0.5s;
  background-size: 200% auto;
  color: white;
  box-shadow: 0 0 20px #eee;
  border-radius: 10px;
  display: block;
}

.btn-quote:hover {
  background-position: right center;
  color: #fff;
  text-decoration: none;
  cursor: pointer;
}

.btn-free {
  background-image: linear-gradient(
    to right,
    #e0eafc 0%,
    #cfdef3 51%,
    #e0eafc 100%
  );
}
.btn-free {
  margin: 10px;
  padding: 15px 45px;
  text-align: center;
  text-transform: uppercase;
  transition: 0.5s;
  background-size: 200% auto;
  color: #000;
  box-shadow: 0 0 20px #eee;
  border-radius: 10px;
  display: block;
}

.btn-free:hover {
  background-position: right center;
  color: #000;
  text-decoration: none;
  cursor: pointer;
}

img {
  -webkit-mask-image: linear-gradient(
    45deg,
    #000 25%,
    rgba(0, 0, 0, 0.2) 50%,
    #000 75%
  );
  mask-image: linear-gradient(
    45deg,
    #000 25%,
    rgba(0, 0, 0, 0.2) 50%,
    #000 75%
  );
  -webkit-mask-size: 800%;
  mask-size: 800%;
  -webkit-mask-position: 0;
  mask-position: 0;
}

img:hover {
  transition: mask-position 2s ease, -webkit-mask-position 2s ease;
  -webkit-mask-position: 120%;
  mask-position: 120%;
  opacity: 1;
}

::-webkit-scrollbar {
  width: 7px;
  height: 7px;
}

::-webkit-scrollbar-track {
  background: #d1e5ff;
}

::-webkit-scrollbar-thumb {
  background: linear-gradient(#642bff, #ff22e6);
  border-radius: 10px;
}

@keyframes slideInFromLeft {
  from {
    transform: translateX(-100%);
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes slideInFromRight {
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes slideInFromBottom {
  from {
    transform: translateY(100%);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
