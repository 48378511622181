.section10-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px 200px;
  width: 100vw;
  height: max-content;
  background: var(--extra-light-gray-color);

  .section10-container-details {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;

    .section10-heading {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      text-align: center;
      width: 100%;

      .span-shape {
        display: flex;
        align-items: center;
        gap: 10px;
        margin-bottom: 10px;

        .shape {
          position: relative;
          width: 50px;
          height: 2px;
          background-color: var(--purple-color);

          &::before,
          &::after {
            content: "";
            position: absolute;
            background-color: var(--purple-color);
            height: 2px;
          }

          &::before {
            width: 30px;
            top: -5px;
            left: 0;
          }

          &::after {
            width: 30px;
            bottom: -5px;
            left: 0;
          }
        }

        span {
          font-size: 16px;
          color: var(--purple-color);
          font-weight: var(--medium);
          text-transform: uppercase;
        }
      }

      h3 {
        font-size: var(--h1-font);
        font-weight: var(--regular);
        text-transform: capitalize;
        color: transparent;
        background-image: linear-gradient(
          to right,
          var(--magenta-bright-color),
          var(--violet-dark-color)
        );
        background-clip: text;
        -webkit-background-clip: text;
      }

      p {
        font-size: var(--normal-font);
        text-align: center;
        line-height: 1.6;
      }
    }

    .section10-details {
      display: flex;
      flex-direction: column;
      width: 100%;
      margin-top: 20px;

      .faq-item {
        background: white;
        padding: 15px;
        border-radius: 5px;
        box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
        margin-bottom: 10px;
        cursor: pointer;

        &.active {
          border: 2px solid var(--purple-color);
        }

        .faq-question {
          display: flex;
          justify-content: space-between;
          font-size: 16px;
          font-weight: bold;
          color: var(--dark-gray-color);

          .faq-icon {
            font-size: 18px;
            font-weight: var(--medium);
            color: var(--dark-gray-color);
          }
        }

        .faq-answer {
          margin-top: 10px;
          font-size: var(--normal-font);
          line-height: 1.5;
          letter-spacing: 1px;
          color: var(--gray-color);
        }
      }
    }
  }
}
