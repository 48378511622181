.section4-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px 200px;
  width: 100vw;
  height: max-content;
  background: var(--extra-light-gray-color);

  .section4-container-details {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    justify-content: center;

    .section4-heading {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      text-align: center;
      width: 100%;

      .span-shape {
        display: flex;
        align-items: center;
        gap: 10px;
        margin-bottom: 10px;

        .shape {
          position: relative;
          width: 50px;
          height: 2px;
          background-color: var(--purple-color);

          &::before,
          &::after {
            content: "";
            position: absolute;
            background-color: var(--purple-color);
            height: 2px;
          }

          &::before {
            width: 30px;
            top: -5px;
            left: 0;
          }

          &::after {
            width: 30px;
            bottom: -5px;
            left: 0;
          }
        }

        span {
          font-size: 16px;
          color: var(--purple-color);
          font-weight: var(--medium);
          text-transform: uppercase;
        }
      }

      h3 {
        font-size: var(--h1-font);
        font-weight: var(--regular);
        text-transform: capitalize;
        color: transparent;
        background-image: linear-gradient(
          to right,
          var(--magenta-bright-color),
          var(--violet-dark-color)
        );
        background-clip: text;
        -webkit-background-clip: text;
      }

      p {
        font-size: var(--normal-font);
        text-align: center;
        line-height: 1.6;
      }
    }

    .section4-tab-container {
      display: flex;
      flex-direction: column;
      margin-top: 20px;
      width: 100%;

      .tabs {
        display: flex;
        justify-content: space-around;
        flex-wrap: wrap;
        margin-bottom: 20px;

        .tab {
          padding: 10px 20px;
          font-size: var(--normal-font);
          color: var(--dark-color);
          background: transparent;
          border: none;
          cursor: pointer;
          position: relative;
          overflow: hidden;
          transition: color 0.3s, background-color 0.3s;
          z-index: 1;
          border-bottom-right-radius: 10px;
          border-top-left-radius: 10px;
          border-right: 2px solid var(--magenta-bright-color);
          border-bottom: 2px solid var(--magenta-bright-color);

          &.active {
            color: var(--white-color);
            background: linear-gradient(
              to right,
              var(--magenta-bright-color),
              var(--violet-dark-color)
            );
          }

          &:hover {
            color: var(--white-color);
            background: transparent;

            &::before {
              content: "";
              position: absolute;
              top: -4px;
              left: -4px;
              right: -4px;
              bottom: -4px;
              border-radius: 10px;
              border: 2px solid transparent;
              background-image: linear-gradient(
                to top right,
                var(--magenta-bright-color),
                var(--violet-dark-color)
              );
              z-index: -1;
            }
          }

          &::before {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            border: 2px solid transparent;
            border-radius: 10px;
          }
        }
      }

      .tab-content {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 15px;
        padding: 20px;
        background-color: transparent;
        border-radius: 10px;
        position: relative;

        h4 {
          font-size: var(--h2-font);
          font-weight: var(--regular);
          color: transparent;
          background-image: linear-gradient(
            to right,
            var(--magenta-bright-color),
            var(--violet-dark-color)
          );
          background-clip: text;
          -webkit-background-clip: text;
        }

        .tab-content-body {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;
          justify-content: center;
          gap: 10px;
          flex: 1;
          width: 100%;
          flex-wrap: wrap;

          .content-image {
            flex: 1;
            display: flex;
            justify-content: center;
            align-items: center;

            img {
              width: 100%;
              max-width: 350px;
              object-fit: contain;
              border-radius: 10px;
            }
          }

          .content-description {
            flex: 2;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            padding: 20px;

            p {
              font-size: var(--normal-font);
              color: var(--dark-color);
              text-align: justify;
              line-height: 1.6;
              letter-spacing: 1px;
            }

            ul {
              list-style: square;
              padding-left: 20px;
              display: flex;
              flex-direction: column;
              gap: 10px;
              margin-top: 10px;

              li {
                font-size: var(--normal-font);
                color: var(--dark-color);
                line-height: 1.6;
                letter-spacing: 0.5px;
              }
            }

            span {
              display: inline-block;
              font-weight: var(--regular);
            }

            .primary-button {
              margin-top: 20px;
              width: max-content;
              padding: 10px 20px;
              background-color: var(--primary-color);
              color: white;
              border: none;
              border-radius: 5px;
              cursor: pointer;
              transition: background-color 0.3s;

              &:hover {
                background-color: var(--secondary-color);
              }
            }
          }
        }
      }
    }
  }
}
