.section6-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px 200px;
  width: 100%;
  background: var(--extra-light-gray-color);

  @media (max-width: 768px) {
    padding: 20px;
  }

  .section6-container-details {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;

    .section6-heading {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      text-align: center;
      width: 100%;

      .span-shape {
        display: flex;
        align-items: center;
        gap: 10px;
        margin-bottom: 10px;

        .shape {
          position: relative;
          width: 50px;
          height: 2px;
          background-color: var(--purple-color);

          &::before,
          &::after {
            content: "";
            position: absolute;
            background-color: var(--purple-color);
            height: 2px;
          }

          &::before {
            width: 30px;
            top: -5px;
            left: 0;
          }

          &::after {
            width: 30px;
            bottom: -5px;
            left: 0;
          }
        }

        span {
          font-size: 16px;
          color: var(--purple-color);
          font-weight: var(--medium);
          text-transform: uppercase;
        }
      }

      h3 {
        font-size: var(--h1-font);
        font-weight: var(--regular);
        text-transform: capitalize;
        color: transparent;
        background-image: linear-gradient(
          to right,
          var(--magenta-bright-color),
          var(--violet-dark-color)
        );
        background-clip: text;
        -webkit-background-clip: text;
      }

      p {
        font-size: var(--normal-font);
        text-align: center;
        line-height: 1.6;
      }
    }

    .section6-details {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      grid-gap: 20px;
      width: 100%;
      justify-items: center;
      padding: 20px 0;

      .section6-grid-card {
        background-color: var(--white-color);
        border: 1px solid #ddd;
        border-radius: 8px;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
        display: flex;
        flex-direction: column;
        align-items: start;
        padding: 20px;
        width: 100%;
        transition: transform 0.3s ease, box-shadow 0.3s ease;

        &:hover {
          transform: translateY(-5px);
          box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.1);
          border-bottom: 5px solid;
          border-image: linear-gradient(
              to right,
              var(--magenta-bright-color),
              var(--violet-dark-color)
            )
            1;
        }

        .details-card-heading {
          display: flex;
          align-items: center;
          gap: 15px;

          .card-icon {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 50px;
            height: 50px;
            font-size: 50px;
            color: var(--magenta-bright-color);

            img {
              width: 100%;
              height: auto;
            }
          }

          .card-heading {
            font-size: var(--h2-font);
            font-weight: var(--medium);
            text-align: left;
            text-transform: capitalize;
            color: transparent;
            background-image: linear-gradient(
              to right,
              var(--magenta-bright-color),
              var(--violet-dark-color)
            );
            background-clip: text;
            -webkit-background-clip: text;
            margin: 0;
          }
        }

        ul {
          margin-top: 20px;
          display: flex;
          flex-direction: column;
          list-style: disc !important;
          font-size: var(--normal-font);
          color: var(--grey-color);
          line-height: 1.6;
          letter-spacing: 0.5px;
          padding-left: 20px;
        }
      }
    }
  }
}
