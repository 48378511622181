.section3-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px 200px;
  width: 100vw;
  height: max-content;
  background: var(--white-color);

  .section3-details {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    justify-content: center;

    .section3-heading {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      text-align: center;
      width: 100%;

      .span-shape {
        display: flex;
        align-items: center;
        gap: 10px;
        margin-bottom: 10px;

        .shape {
          position: relative;
          width: 50px;
          height: 2px;
          background-color: var(--purple-color);

          &::before,
          &::after {
            content: "";
            position: absolute;
            background-color: var(--purple-color);
            height: 2px;
          }

          &::before {
            width: 30px;
            top: -5px;
            left: 0;
          }

          &::after {
            width: 30px;
            bottom: -5px;
            left: 0;
          }
        }

        span {
          font-size: 16px;
          color: var(--purple-color);
          font-weight: var(--medium);
          text-transform: uppercase;
        }
      }

      h3 {
        font-size: var(--h1-font);
        font-weight: var(--regular);
        text-transform: capitalize;
        color: transparent;
        background-image: linear-gradient(
          to right,
          var(--magenta-bright-color),
          var(--violet-dark-color)
        );
        background-clip: text;
        -webkit-background-clip: text;
      }

      p {
        font-size: var(--normal-font);
        text-align: center;
        line-height: 1.6;
      }
    }

    .section3-grid-container {
      margin-top: 20px;
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      grid-gap: 40px;
      width: 100%;
      height: 150px;

      .container {
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 10px;
        padding: 20px;
        background: var(--white-color);
        border-radius: 20px;
        z-index: 0;
        transition: background 0.3s ease, transform 0.3s ease;

        &:hover {
          background: linear-gradient(
            to right,
            var(--magenta-bright-color),
            var(--violet-dark-color)
          );
          transform: scale(1.05);
        }

        &::before {
          content: "";
          position: absolute;
          top: -2px;
          left: -2px;
          right: -2px;
          bottom: -2px;
          background: linear-gradient(
            90deg,
            var(--magenta-bright-color),
            var(--violet-dark-color)
          );
          border-radius: 22px;
          z-index: -2;
          transition: background 0.3s ease;
        }

        &::after {
          content: "";
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          background: var(--white-color);
          border-radius: 20px;
          z-index: -1;
        }

        span {
          font-size: var(--h1-font);
          font-weight: var(--medium);
          color: transparent;
          background-image: linear-gradient(
            to right,
            var(--magenta-bright-color),
            var(--violet-dark-color)
          );
          background-clip: text;
          -webkit-background-clip: text;
          transition: background-image 0.3s ease;
        }

        p {
          font-size: var(--h3-font);
          color: var(--purple-color);
          text-transform: capitalize;
        }
      }
    }
  }
}
