.scroll-up {
  position: fixed;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  padding: 10px 15px;
  font-size: 24px;
  background-color: var(--violet-color);
  color: #fff;
  border: none;
  border-radius: 50%;
  cursor: pointer;
  opacity: 0.8;
  transition: opacity 0.3s ease;
  z-index: 1000;

  &:hover {
    opacity: 1;
  }
}
