.about-section5-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px 200px;
  width: 100vw;
  height: max-content;
  background: var(--extra-light-gray-color);

  .about-section5-details {
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: 100%;

    .about-section5-intro {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      opacity: 0;
      animation: slideInFromLeft 1s ease-out forwards;

      .span-shape {
        display: flex;
        align-items: center;
        gap: 10px;
        margin-bottom: 10px;

        .shape {
          position: relative;
          width: 50px;
          height: 2px;
          background-color: var(--purple-color);

          &::before,
          &::after {
            content: "";
            position: absolute;
            background-color: var(--purple-color);
            height: 2px;
          }

          &::before {
            width: 30px;
            top: -5px;
            left: 0;
          }

          &::after {
            width: 30px;
            bottom: -5px;
            left: 0;
          }
        }

        span {
          font-size: 16px;
          color: var(--purple-color);
          font-weight: var(--medium);
          text-transform: uppercase;
        }
      }

      h3 {
        font-size: var(--h1-font);
        font-weight: var(--regular);
        margin-top: 10px;
        text-transform: capitalize;
        color: transparent;
        background-image: linear-gradient(
          to right,
          var(--magenta-bright-color),
          var(--violet-dark-color)
        );
        background-clip: text;
        -webkit-background-clip: text;
        text-align: center;
      }

      p {
        font-size: var(--normal-font);
        text-align: center;
        line-height: 1.6;
        margin-bottom: 10px;
      }
    }

    .about-section5-info-container {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      flex-wrap: wrap;
      gap: 20px;
      margin-top: 20px;

      .info {
        display: flex;
        flex-direction: row;
        flex: 1;
        height: auto;
        max-width: calc(100% - 20px);
        background: #fff;
        box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
        border-radius: 8px;
        padding: 20px;
        text-align: left;

        &:nth-child(odd) {
          flex-direction: row-reverse;
        }

        img {
          height: 250px;
          object-fit: cover;
          border-radius: 8px;
        }

        .text-content {
          flex: 1;
          padding: 20px;

          h5 {
            font-size: 18px;
            font-weight: var(--regular);
            text-transform: capitalize;
            color: transparent;
            background-image: linear-gradient(
              to right,
              var(--magenta-bright-color),
              var(--violet-dark-color)
            );
            background-clip: text;
            -webkit-background-clip: text;
            margin-bottom: 10px;
          }

          p {
            font-size: var(--normal-font);
            text-align: justify;
            line-height: 1.4;
          }
        }
      }
    }
  }
}
