header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 60px;
  background-color: var(--white-color);
  color: var(--dark-color);
  height: 100px;
  box-shadow: var(--box-shadow);
  z-index: 1000;

  &.scrolled {
    .menu-icon {
      display: none;
    }

    .need-help{
      opacity: 1;
      margin-left: auto;
    }

    button {
      margin-left: auto;
      display: block;
    }
  }

  .logo {
    img {
      height: 80px;
      object-fit: cover;
    }
  }

  .menu-icon {
    display: flex;
    gap: 10px;
    align-items: center;
    justify-content: center;

    .menus {
      display: flex;
      gap: 20px;
      align-items: center;
      justify-content: center;

      .menu-items {
        display: flex;
        gap: 20px;
        align-items: center;

        a {
          text-decoration: none;
          color: var(--magenta-bright-color);
          font-size: var(--normal-font);
          font-weight: var(--regular);
          position: relative;
          transition: color 0.3s ease;

          &:hover {
            color: var(--purple-color);
          }

          &::after {
            content: "";
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 2px;
            background-color: var(--purple-color);
            transform: scaleX(0);
            transform-origin: bottom right;
            transition: transform 0.3s ease;
          }

          &:hover::after {
            transform: scaleX(1);
            transform-origin: bottom left;
          }
        }
      }
    }

    .language-toggle {
      display: flex;
      gap: 0.5rem;

      button {
        display: flex;
        align-items: center;
        justify-content: center;
        background: transparent;
        border-radius: 5px;
        padding: 5px 10px;
        cursor: pointer;
        border: none !important;
        font-size: 1rem;
        color: #333;

        &.active {
          background: linear-gradient(
            45deg,
            var(--magenta-bright-color),
            var(--violet-dark-color)
          );
          color: #fff;
        }

        &:hover {
          background: linear-gradient(
            45deg,
            var(--magenta-bright-color),
            var(--violet-dark-color)
          );
          color: #fff;
        }

        img {
          width: 20px;
          height: 14px;
        }
      }
    }
  }

  .need-help {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    opacity: 0;

    img {
      width: 40px;
      object-fit: cover;
    }

    .help-details {
      display: flex;
      flex-direction: column;
      text-align: center;

      span {
        font-size: 10px;
        text-transform: uppercase;
        color: var(--slate-color);
      }

      p {
        margin: 0;
        font-size: 12px;
      }
    }
  }

  button {
    border: none;
    outline: none;
    background: transparent;
    padding: 10px;
    font-size: var(--h2-font);
    color: var(--magenta-bright-color);
    transition: all 0.3s ease;
    display: none;
    z-index: 1000;

    &:hover {
      cursor: pointer;
    }
  }

  .right-panel {
    position: fixed;
    top: 0;
    right: -30%;
    width: 30%;
    height: 100vh;
    background-color: #fff;
    box-shadow: -2px 0 5px rgba(0, 0, 0, 0.2);
    transition: right 0.3s ease;
    z-index: 999;

    &.open {
      right: 0;
    }

    .right-panel-content {
      display: flex;
      flex-direction: column;
      gap: 20px;
      padding: 20px;

      img {
        height: 100px;
        object-fit: contain;
      }

      .right-panel-language-toggle {
        display: flex;
        gap: 0.5rem;
        width: max-content;

        button {
          display: flex;
          align-items: center;
          justify-content: center;
          background: transparent;
          border-radius: 5px;
          padding: 5px 10px;
          cursor: cursor;
          font-size: 1rem;
          color: #333;

          &.active {
            background: linear-gradient(
              45deg,
              var(--magenta-bright-color),
              var(--violet-dark-color)
            );
            color: #fff;
          }

          &:hover {
            background: linear-gradient(
              45deg,
              var(--magenta-bright-color),
              var(--violet-dark-color)
            );
            color: #fff;
          }

          img {
            width: 20px;
            height: 14px;
          }
        }
      }

      h3 {
        font-size: var(--h3-font);
        font-weight: var(--regular);
        color: var(--magenta-bright-color);
        cursor: pointer;
        position: relative;
        transition: color 0.3s ease;

        &:hover {
          color: var(--purple-color);
        }

        &::after {
          content: "";
          position: absolute;
          bottom: 0;
          left: 0;
          width: 30px;
          height: 2px;
          background-color: var(--purple-color);
          transform: scaleX(0);
          transform-origin: bottom right;
          transition: transform 0.3s ease;
        }

        &:hover::after {
          transform: scaleX(1);
          transform-origin: bottom left;
        }
      }

      .social-icons {
        display: flex;
        gap: 15px;
        padding: 20px 0;

        a {
          color: var(--violet-color);
          font-size: 1.5rem;
          transition: color 0.3s ease;

          &:hover {
            color: var(--magenta-bright-color);
          }
        }
      }

      .rtl {
        direction: rtl;
        text-align: right;
      }
    }
  }

  .left-panel {
    position: fixed;
    top: 0;
    left: -70%;
    width: 70%;
    height: 100vh;
    background-color: #f5f5f5;
    box-shadow: 2px 0 5px rgba(0, 0, 0, 0.2);
    transition: left 0.3s ease;
    z-index: 998;

    &.visible {
      left: 0;
    }

    .left-panel-content {
      display: flex;
      flex-direction: column;
      gap: 20px;
      padding: 20px;

      h1 {
        font-size: var(--h1-font);
        font-weight: var(--regular);
        color: transparent;
        background-image: linear-gradient(
          to right,
          var(--magenta-bright-color),
          var(--violet-dark-color)
        );
        background-clip: text;
        -webkit-background-clip: text;
      }

      p {
        font-size: var(--normal-font);
      }

      .homepage-links {
        margin-top: 10px;
        h3 {
          font-size: 1.5rem;
          color: transparent;
          background-image: linear-gradient(
            to right,
            var(--magenta-bright-color),
            var(--violet-dark-color)
          );
          background-clip: text;
          -webkit-background-clip: text;
        }
        p {
          font-size: 1rem;
          color: #666;
          margin-bottom: 10px;
        }
        ul {
          list-style-type: none;
          padding: 0;
          li {
            margin: 5px 0;
            a {
              text-decoration: none;
              color: var(--magenta-bright-color) !important;
              &:hover {
                text-decoration: underline;
              }
            }
          }
        }
      }

      .services-links {
        margin-top: 20px;
        display: grid;
        grid-template-columns: repeat(4, 1fr);

        .column1,
        .column2,
        .column3,
        .column4 {
          display: flex;
          flex-direction: column;
          gap: 20px;
          ul {
            list-style: none;
          }
          li h3 {
            font-size: var(--h4-font);
            font-weight: var(--regular);
            position: relative;
            color: transparent;
            background-image: linear-gradient(
              to right,
              var(--magenta-bright-color),
              var(--violet-dark-color)
            );
            background-clip: text;
            -webkit-background-clip: text;

            &::after {
              content: "";
              position: absolute;
              bottom: -2px;
              left: 0;
              width: 160px;
              height: 2px;
              background-color: var(--purple-color);
              transform: scaleX(0);
              transform-origin: bottom left;
              transition: transform 0.3s ease;
            }

            &:hover::after {
              transform: scaleX(1);
            }
          }

          li ul {
            list-style: square;
            display: flex;
            flex-direction: column;
            gap: 10px;
            padding: 0 0 0 20px;
            margin: 20px 0;

            li {
              a {
                color: var(--purple-color);
              }
            }
          }
        }
      }
    }
  }
}
