.section9-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 40px 20px;
  width: 100%;
  background: var(--white-color);

  .section9-container-details {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    max-width: 1200px;

    .section9-heading {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      text-align: center;
      margin-bottom: 20px;

      .span-shape {
        display: flex;
        align-items: center;
        gap: 10px;
        margin-bottom: 10px;

        .shape {
          position: relative;
          width: 50px;
          height: 2px;
          background-color: var(--purple-color);

          &::before,
          &::after {
            content: "";
            position: absolute;
            background-color: var(--purple-color);
            height: 2px;
          }

          &::before {
            width: 30px;
            top: -5px;
            left: 0;
          }

          &::after {
            width: 30px;
            bottom: -5px;
            left: 0;
          }
        }

        span {
          font-size: 16px;
          color: var(--purple-color);
          font-weight: var(--medium);
          text-transform: uppercase;
        }
      }

      h3 {
        font-size: var(--h1-font);
        font-weight: var(--regular);
        text-transform: capitalize;
        color: transparent;
        background-image: linear-gradient(
          to right,
          var(--magenta-bright-color),
          var(--violet-dark-color)
        );
        background-clip: text;
        -webkit-background-clip: text;
        margin-bottom: 10px;
      }

      p {
        font-size: var(--normal-font);
        text-align: center;
        line-height: 1.6;
        margin: 0 10px;
      }
    }

    .section9-details {
      display: flex;
      justify-content: space-between;
      width: 100%;
      gap: 20px;
      margin-top: 20px;

      .details-info {
        display: flex;
        flex-direction: column;
        align-items: start;
        justify-content: flex-start;
        flex: 1;
        height: auto;
        text-align: left;
        padding: 10px;

        h3 {
          font-size: var(--h2-font);
          font-weight: var(--regular);
          text-transform: capitalize;
          color: transparent;
          background-image: linear-gradient(
            to right,
            var(--magenta-bright-color),
            var(--violet-dark-color)
          );
          background-clip: text;
          -webkit-background-clip: text;
          margin-bottom: 10px;
        }

        ul {
          list-style: square;
          display: flex;
          flex-direction: column;
          gap: 10px;
          margin-left: 30px;
        }
      }

      .details-form {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        flex: 2;
        height: auto;
        padding: 10px;

        form {
          display: flex;
          flex-direction: column;
          gap: 20px;
          padding: 20px;
          width: 100%;
          height: 100%;

          .form-group {
            position: relative;

            input[type="email"],
            input[type="text"],
            input[type="password"],
            input[type="file"],
            textarea {
              padding: 12px;
              width: 100%;
              height: 60px;
              max-width: 100%;
              border-radius: 4px;
              box-sizing: border-box;
              outline: none;
              border: 1px solid #ccc;
              background-color: #fff;
              transition: border-color 0.3s ease;

              &:focus {
                border-color: #0677c8;
              }
            }

            label {
              position: absolute;
              top: -12px;
              left: 12px;
              font-size: 14px;
              background: #fff;
              color: #666;
              pointer-events: none;
              transition: all 0.2s ease;
              z-index: 3;
              padding: 0 5px;
            }

            textarea {
              height: 150px;
            }
          }

          .form-contents {
            display: flex;
            justify-content: space-between;
            gap: 20px;
            align-items: center;

            .form-group {
              width: 100%;
            }
          }

          .check-group {
            display: flex;
            align-items: center;
            gap: 10px;
            margin-bottom: 10px;

            input[type="checkbox"] {
              width: 20px;
              height: 20px;
              cursor: pointer;
              accent-color: var(
                --purple-color
              );
            }

            label {
              font-size: var(--normal-font);
              color: var(--gray-color);
              cursor: pointer;
            }
          }
        }
      }
    }
  }
}
