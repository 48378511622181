.chatbot-container {
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 1000;

  .chatbot-icon {
    background-color: #007bff;
    color: white;
    font-size: 24px;
    padding: 10px;
    border-radius: 50%;
    border: none;
    cursor: pointer;
    transition: background-color 0.3s;

    &:hover {
      background-color: #0056b3;
    }
  }

  .chatbot-window {
    position: fixed;
    bottom: 70px;
    right: 20px;
    width: 300px;
    max-height: 400px;
    background-color: white;
    border: 1px solid #ddd;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    display: flex;
    flex-direction: column;
    overflow: hidden;

    .chatbot-header {
      background-color: #007bff;
      color: white;
      padding: 10px;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .close-btn {
        background: none;
        border: none;
        color: white;
        font-size: 16px;
        cursor: pointer;
      }
    }

    .chatbot-body {
      padding: 10px;
      overflow-y: auto;
    }
  }
}
