.about-section4-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px 200px;
  width: 100vw;
  height: max-content;
  background: var(--white-color);

  .about-section4-details {
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: 100%;

    .about-section4-intro {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      opacity: 0;
      animation: slideInFromLeft 1s ease-out forwards;

      .span-shape {
        display: flex;
        align-items: center;
        gap: 10px;
        margin-bottom: 10px;

        .shape {
          position: relative;
          width: 50px;
          height: 2px;
          background-color: var(--purple-color);

          &::before,
          &::after {
            content: "";
            position: absolute;
            background-color: var(--purple-color);
            height: 2px;
          }

          &::before {
            width: 30px;
            top: -5px;
            left: 0;
          }

          &::after {
            width: 30px;
            bottom: -5px;
            left: 0;
          }
        }

        span {
          font-size: 16px;
          color: var(--purple-color);
          font-weight: var(--medium);
          text-transform: uppercase;
        }
      }

      h3 {
        font-size: var(--h1-font);
        font-weight: var(--regular);
        margin-top: 10px;
        text-transform: capitalize;
        color: transparent;
        background-image: linear-gradient(
          to right,
          var(--magenta-bright-color),
          var(--violet-dark-color)
        );
        background-clip: text;
        -webkit-background-clip: text;
        text-align: center;
      }

      p {
        font-size: var(--normal-font);
        text-align: center;
        line-height: 1.6;
        margin-bottom: 10px;
      }
    }

    .about-section4-info-container {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      flex-wrap: wrap;
      gap: 20px;
      margin-top: 20px;

      .info {
        flex: 1;
        height: 250px;
        max-width: calc(33.33% - 20px);
        background: #fff;
        box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
        border-radius: 8px;
        padding: 20px;
        text-align: center;

        img {
          width: 50px;
          height: 50px;
          object-fit: contain;
          margin-bottom: 10px;
        }

        h5 {
          font-size: 18px;
          font-weight: var(--regular);
          text-transform: capitalize;
          color: transparent;
          background-image: linear-gradient(
            to right,
            var(--magenta-bright-color),
            var(--violet-dark-color)
          );
          background-clip: text;
          -webkit-background-clip: text;
          margin-bottom: 10px;
        }

        p {
          font-size: var(--normal-font);
          text-align: justify;
          line-height: 1.4;
        }
      }
    }
  }
}
