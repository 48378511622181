.section8-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px 200px;
  width: 100vw;
  height: max-content;
  background: var(--extra-light-gray-color);

  .section8-container-details {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;

    .section8-heading {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      text-align: center;
      width: 100%;

      .span-shape {
        display: flex;
        align-items: center;
        gap: 10px;
        margin-bottom: 10px;

        .shape {
          position: relative;
          width: 50px;
          height: 2px;
          background-color: var(--purple-color);

          &::before,
          &::after {
            content: "";
            position: absolute;
            background-color: var(--purple-color);
            height: 2px;
          }

          &::before {
            width: 30px;
            top: -5px;
            left: 0;
          }

          &::after {
            width: 30px;
            bottom: -5px;
            left: 0;
          }
        }

        span {
          font-size: 16px;
          color: var(--purple-color);
          font-weight: var(--medium);
          text-transform: uppercase;
        }
      }

      h3 {
        font-size: var(--h1-font);
        font-weight: var(--regular);
        text-transform: capitalize;
        color: transparent;
        background-image: linear-gradient(
          to right,
          var(--magenta-bright-color),
          var(--violet-dark-color)
        );
        background-clip: text;
        -webkit-background-clip: text;
      }

      p {
        font-size: var(--normal-font);
        text-align: center;
        line-height: 1.6;
      }
    }

    .section8-details {
      margin-top: 30px;
      width: 100%;

      .scrolling-wrapper {
        display: flex;
        flex: 0 0 auto;
        animation: scroll 20s linear infinite;
        animation-play-state: running;
        padding: 10px 0;
        width: 100%;
        justify-content: flex-start;

        &:hover {
          animation-play-state: paused;
        }

        .card {
          flex: 0 0 auto;
          width: 300px;
          height: 500px;
          margin-right: 20px;
          background-color: var(--white-color);
          border: 1px solid #ddd;
          border-radius: 8px;
          box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
          display: flex;
          flex-direction: column;
          padding: 20px;
          transition: transform 0.3s ease, box-shadow 0.3s ease;
          opacity: 1;
          overflow: hidden;

          &:hover {
            transform: translateY(-5px);
            box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.1);
          }

          img {
            width: 100%;
            height: 200px;
            object-fit: cover;
            border-radius: 8px;
            margin-bottom: 15px;
          }

          .card-content {
            display: flex;
            flex-direction: column;
            justify-content: center;
            height: calc(100% - 220px);
            overflow: hidden;
            padding-bottom: 10px;

            h4 {
              font-size: var(--h3-font);
              margin-bottom: 10px;
              color: transparent;
              background-image: linear-gradient(
                to right,
                var(--magenta-bright-color),
                var(--violet-dark-color)
              );
              background-clip: text;
              -webkit-background-clip: text;
            }

            p {
              font-size: var(--normal-font);
              color: var(--slate-color);
              margin-bottom: 10px;

              span {
                font-size: var(--h6-font);
                color: var(--gray-color);
              }
            }

            ul {
              list-style: none;
              padding: 0;

              li {
                font-size: var(--normal-font);
                color: var(--gray-color);
                margin: 5px 0;
              }
            }

            .content-truncate {
              font-size: var(--normal-font);
              color: var(--gray-color);
              display: -webkit-box;
              -webkit-line-clamp: 2;
              -webkit-box-orient: vertical;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: normal;
              max-width: 100%;
            }
          }
        }
      }
    }
  }

  @keyframes scroll {
    from {
      transform: translateX(0);
    }
    to {
      transform: translateX(-100%);
    }
  }
}
