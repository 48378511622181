.section12-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px 200px;
  width: 100vw;
  height: auto;
  background: var(--extra-light-gray-color);

  .section12-container-details {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;

    .section12-heading {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      text-align: center;
      width: 100%;

      .span-shape {
        display: flex;
        align-items: center;
        gap: 10px;
        margin-bottom: 10px;

        .shape {
          position: relative;
          width: 50px;
          height: 2px;
          background-color: var(--purple-color);

          &::before,
          &::after {
            content: "";
            position: absolute;
            background-color: var(--purple-color);
            height: 2px;
          }

          &::before {
            width: 30px;
            top: -5px;
            left: 0;
          }

          &::after {
            width: 30px;
            bottom: -5px;
            left: 0;
          }
        }

        span {
          font-size: 16px;
          color: var(--purple-color);
          font-weight: var(--medium);
          text-transform: uppercase;
        }
      }

      h3 {
        font-size: var(--h1-font);
        font-weight: var(--regular);
        text-transform: capitalize;
        color: transparent;
        background-image: linear-gradient(
          to right,
          var(--magenta-bright-color),
          var(--violet-dark-color)
        );
        background-clip: text;
        -webkit-background-clip: text;
      }

      p {
        font-size: var(--normal-font);
        text-align: center;
        line-height: 1.6;
      }
    }

    .section12-details {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      margin-top: 40px;

      .testimonial-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background: var(--white-color);
        padding: 20px;
        border-radius: 10px;
        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
        max-width: 500px;
        width: 100%;

        .testimonial-image img {
          border-radius: 50%;
          width: 100px;
          height: 100px;
          object-fit: cover;
        }

        .testimonial-info {
          text-align: center;
          margin-top: 20px;

          .designation {
            font-size: 14px;
            color: #555;
          }

          .rating {
            margin-top: 10px;
            font-size: 18px;

            .star {
              color: #ffcc00;
              font-size: 20px;
            }

            .filled {
              color: #ffcc00;
            }
          }

          .testimonial {
            font-size: 16px;
            margin-top: 15px;
            color: #333;
          }
        }
      }
    }
  }
}
